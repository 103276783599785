import "./feed.css";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'

TimeAgo.addDefaultLocale(en)
const timeAgo = new TimeAgo('en-US')

const Doc = ({ title, timestamp, url }) => {
  if (title === "") {
    return <div class="doc-card">Loading...</div>;
  }

  // update the datea to the "X days ago format"
  const docDate = new Date(timestamp)
  docDate.setHours(12)
  const printableDate = timeAgo.format(docDate)

  return (
    <div class="doc-card">
      <article>
        <a href={url} target="_blank" rel="noreferrer">
          <h3 class="deal-title">{title}</h3>
        </a>
        <p class="deal-timestamp">{printableDate}</p>
      </article>
    </div>
  );
};

export default Doc;
