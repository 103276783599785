import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Navbar from "../navbar/navbar";
import Feed from "../feed/feed";
import Home from "../home/home";
import Footer from "../footer/footer";
// import Structures from "../structures/structures";
import "./app.css";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


function App() {
  return (
    <div class="Wrapper">
    <Router>
      <div class="App">
        <div class="wrapper">
          <header class="App-header">
              <Navbar />
          </header>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/index" element={<Home />} />
            {/* <Route exact path="/structures" element={<Structures />} /> */}
            <Route exact path="/feed" element={<Feed />} />
          </Routes>
        </div>
      </div>
    </Router>
    <Footer />
    </div>
  );
}

export default App;
