import db from "../admin";
import Doc from "./doc";
import { useEffect, useState } from "react";
import {
  onSnapshot,
  collection,
  limit,
  query,
  orderBy,
  startAfter,
} from "@firebase/firestore";

import "./feed.css";

function LoadFeed({ tf, func }) {
  if (tf) {
    return (
      <div className="ending">
        <span className="ending-action">End of feed</span>
      </div>
    );
  }
  return (
    <div className="ending">
      <button className="ending-action btn btn-primary" onClick={func}>
        More deals
      </button>
    </div>
  );
}

function Feed() {
  // Create  an initial state which will render as "loading"
  const placeholder = [
    { id: "a", title: "", url: "", source: "", timestamp: "" },
    { id: "b", title: "", url: "", source: "", timestamp: "" },
    { id: "c", title: "", url: "", source: "", timestamp: "" },
    { id: "d", title: "", url: "", source: "", timestamp: "" },
    { id: "e", title: "", url: "", source: "", timestamp: "" },
  ];

  // Include react hooks to initiate feed
  const [docs, setDocs] = useState(placeholder);
  const [timestamp, setTimestamp] = useState("");
  const [finished, setFinsihed] = useState(false);

  // React "Effects" are used like async functions
  useEffect(() => {
    const feedRef = collection(db, "redirectFeed");
    const feedQuery = query(feedRef, orderBy("timestamp", "desc"), limit(10));

    onSnapshot(feedQuery, (snapshot) => {
      const docs = snapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }))
        .filter((doc) => doc.ignore !== true);

      setDocs(docs);
      setTimestamp(docs[docs.length - 1].timestamp);
    });
  }, []);

  // Generic function that updates the feed with new results
  const update = () => {
    var buffer = docs;
    const feedRef = collection(db, "redirectFeed");
    const feedQuery = query(
      feedRef,
      orderBy("timestamp", "desc"),
      startAfter(timestamp),
      limit(10)
    );

    onSnapshot(feedQuery, (snapshot) => {
      const newDocs = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      if (newDocs.length > 1) {
        setDocs(buffer.concat(newDocs));
        setTimestamp(newDocs[newDocs.length - 1].timestamp);
      } else {
        setFinsihed(true);
      }
    });
  };

  // HTML to pass to the virtual dom
  return (
    <div className="page-card">
      <h1 className="page-header">Africa's latest venture deals</h1>
      {/* <div className="feed"> */}
      {docs.map((doc) => (
        <div key={doc["id"]}>
          <Doc {...doc} />
        </div>
      ))}
      <LoadFeed tf={finished} func={update} />
      {/* </div> */}
    </div>
  );
}

export default Feed;
