import { React, useState } from "react";
import { addDoc, collection } from "firebase/firestore";
import db from "../admin";
import "./signup.css";

function SuccessSpan(props) {
  const tf = props.submitted;
  if (tf) {
    return <span id="success-message">Email added</span>;
  }
  return <div></div>;
}

function Signup() {
  // Store the # of submissions in a submits state
  const [submits, setSubmits] = useState(false);
  const [disable, setDisable] = useState(false);

  // use an async function to add doc and update state
  async function submitSignup(e) {
    e.preventDefault();

    // pulls values from the submit event
    const name = e.target.name.value;
    const company = e.target.company.value;
    const email = e.target.email.value;

    // Adds a document to the database
    await addDoc(collection(db, "signups"), {
      name: name,
      company: company,
      email: email,
    });

    setSubmits(true);
    setDisable(true);
  }

  function submitHandler(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  return (
    <div class="showcase-form card" name="signup-form">
      <h2>Join the Structured community</h2>
      <form onSubmit={submitSignup} onKeyPress={submitHandler}>
        <div class="form-control">
          <input type="text" name="name" placeholder="Name" required />
        </div>
        <div class="form-control">
          <input
            type="text"
            name="company"
            placeholder="Company Name"
            required
          />
        </div>
        <div class="form-control">
          <input type="email" name="email" placeholder="Email" required />
        </div>
        <div class="form-submit-control">
          <input type="submit" value="Send" class="btn btn-primary" />
          <SuccessSpan submitted={submits} disabled={disable} />
        </div>
      </form>
    </div>
  );
}

export default Signup;
