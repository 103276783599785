import React from "react";
import Signup from "./signup";

function Home() {
  return (
    <div class="content">
      <section class="showcase">
        <div class="container grid">
          <div class="showcase-text">
            <h1>
              Seamless Africa Deal Feed, Simplified Startup Deal Structures and
              Valuations
            </h1>
            <p>
              Browse Africa startup deals in one place. Sign up today to join
              the Structured Platform.
              {/* Build a collaborative deal structure in minutes. */}
            </p>
            {/* <a href="features.html" class="btn btn-outline">
              Read More
            </a> */}
          </div>
          <Signup />
        </div>
      </section>

      <section class="stats">
        <div class="container">
          <h3 class="stats-heading text-center my-1">
            Leveling the investment playing field for African founders. Access
            the same information & tools Africa's leading investors have.
            Simpler, Faster.
          </h3>

          <div class="grid grid-2 text-center my-4">
            <div>
              <i class="fas fa-database fa-3x"></i>
              <h3>1,000+</h3>
              <p class="text-secondary">African Startups</p>
            </div>
            <div>
              <i class="fas fa-rss fa-3x"></i>
              <h3>25+</h3>
              <p class="text-secondary">Aggregated Deal Sources</p>
            </div>
            {/* <div>
              <i class="fas fa-file fa-3x"></i>
              <h3>3</h3>
              <p class="text-secondary">Flexible Deal Structures</p>
            </div> */}
          </div>
        </div>
      </section>

      {/* <section class="bg-primary my-2 py-2">
        <div class="container grid">
          <div class="text-center">
            <h2 class="lg">Customized SHIELD Agreement</h2>
            <p class="lead my-1">
              Explore the Simple Hybrid Investment Equity Linked Derivative
              (SHIELD). Crafted by African founders. Built by Africa's leading
              startup lawfirms. Backed Silicon valley's best legal minds
            </p>
            <a href="structures" class="btn btn-dark">
              Read More
            </a>
          </div>
        </div>
      </section> */}
    </div>
  );
}

export default Home;
