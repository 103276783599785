import React from "react";
import "./navbar.css";

import { NavLink } from "react-router-dom";

function Navbar() {
  return (
    <div class="navbar">
      <div class="container flex">
        <h1 class="logo">
          <a href="/index">Structured | Africa</a>
        </h1>
        <nav class="navbar-link">
          <ul>
            <li>
              <NavLink exact class="navlink" to="/index">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink exact class="navlink" to="/feed">
                Feed
              </NavLink>
            </li>
            {/* <li>
              <NavLink exact activeClassName="active" class="navlink__highlighted" to="/structures">
                Structures
              </NavLink>
            </li> */}
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Navbar;
